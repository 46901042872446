import { ToastMessageOptions } from 'primevue/toast';
import { defineComponent } from 'vue';

const notificationsMixin = defineComponent({
  methods: {
    showInProgressNotification(detail: string, styleClass?: string) {
      this.$toast.add({
        type: 'info',
        styleClass,
        detail,
      } as ToastMessageOptions);
    },
    showSuccessNotification(detail?: string, styleClass?: string) {
      this.$toast.add({
        type: 'success',
        life: 5000,
        styleClass,
        detail,
      } as ToastMessageOptions);
    },
    showErrorNotification(detail?: string, styleClass?: string) {
      this.$toast.add({
        type: 'danger',
        life: 5000,
        styleClass,
        detail,
      } as ToastMessageOptions);
    },
    closeNotifications(group?: string) {
      group
        ? document.querySelectorAll(`.${group}`).forEach(item => item.remove())
        : this.$toast.removeAllGroups();
    },
  },
});

export default notificationsMixin;
